import $ from 'jquery'
import 'slick-carousel'

window.jQuery = window.$ = $;

//home page
$('.home_slider .slick_slider').slick({
    "dots": true,
    "dotsClass": "my-dots",
    "infinite": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "adaptiveHeight": true,
    "speed": 300,
    "prevArrow": $(".home_slider .slide_prev"),
    "nextArrow": $(".home_slider .slide_next"),
});


const sliderProduct = {
	"infinite": true,
	"autoplay": true,
	"autoplaySpeed": 3000,
	"slidesToShow": 5,
	"slidesToScroll": 1,
	"adaptiveHeight": true,
	"speed": 300,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1.5
			}
		}
	],
}

var slider_new_rec = $('.slider_new_rec .elements')
    .slick({
		...sliderProduct,
		"prevArrow": $(".slider_new_rec .arrow_prev"),
		"nextArrow": $(".slider_new_rec .arrow_next"),
    })
    .on('afterChange', function (event, slick, currentSlide) {
        $(this).parent().find('.items_count').text((currentSlide + 1) + ' / ' + slick.slideCount)
    }).slick("slickPause");

var initialDelay = 1500;

setTimeout(function() {
	slider_new_rec.slick("slickPlay");
},initialDelay);



$('.slider_new_products .elements')
    .slick({
        ...sliderProduct,
        "prevArrow": $(".slider_new_products .arrow_prev"),
        "nextArrow": $(".slider_new_products .arrow_next"),
    })
    .on('afterChange', function (event, slick, currentSlide) {
        $(this).parent().find('.items_count').text((currentSlide + 1) + ' / ' + slick.slideCount)
    });

$('.slider_additional_products .elements')
    .slick({
		...sliderProduct,
		// "variableWidth": true,
        "prevArrow": $(".slider_additional_products .arrow_prev"),
        "nextArrow": $(".slider_additional_products .arrow_next"),
    })
    .on('afterChange', function (event, slick, currentSlide) {
        $(this).parent().find('.items_count').text((currentSlide + 1) + ' / ' + slick.slideCount)
    });

//product page

$('.product_gallery .gallery_slider .elements')
    .slick({
        "infinite": true,
        // "autoplay": true,
        // "autoplaySpeed": 3000,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        // "variableWidth": true,
        // "adaptiveHeight": true,
        "speed": 300,
        "prevArrow": $(".product_gallery .gallery_slider .prev_arrow"),
        "nextArrow": $(".product_gallery .gallery_slider .next_arrow"),
        "asNavFor": '.gallery_items_list'
    });


    $('.gallery_items_list').slick({
        "slidesToShow": 6,
        "slidesToScroll": 1,
        "asNavFor": '.product_gallery .gallery_slider .elements',
        "vertical": true,
        "verticalSwiping": true,
        // "centerMode": true,
        // "focusOnSelect": true,
        "infinite": true,
        "responsive": [
            {
                "breakpoint": 768,
                "settings": {
                    "vertical": false,
                    "verticalSwiping": false,
                    "slidesToShow": 4,
                    "variableWidth": true,

                }
            }
        ]
      });




$('.product_gallery .gallery_items_list [data-slide]').click(function (e) {
    let slide = $(this).data('slide');
    $('.product_gallery .gallery_slider .elements').slick('slickGoTo', slide);
});



// $('.slider_additional_products .elements').slick({
// 	...sliderProduct,
// 	"prevArrow": $(".slider_additional_products .arrow_prev"),
// 	"nextArrow": $(".slider_additional_products .arrow_next"),
// });

$('.slider_recommend_products .elements').slick({
	...sliderProduct,
	"prevArrow": $(".slider_recommend_products .arrow_prev"),
	"nextArrow": $(".slider_recommend_products .arrow_next"),
})
.on('afterChange', function (event, slick, currentSlide) {
    $(this).parent().find('.items_count').text((currentSlide + 1) + ' / ' + slick.slideCount)
});


$('.category_title_with_slider .category_slider_container')
    .slick({
        "infinite": true,
        "autoplay": true,
        "autoplaySpeed": 3000,
        "slidesToShow": 6,
        "slidesToScroll": 1,
        "adaptiveHeight": true,
        "speed": 300,
		"variableWidth": true,
        "prevArrow": $(".category_title_with_slider .slider_prev"),
        "nextArrow": $(".category_title_with_slider .slider_next"),
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    })
    .on('afterChange', function (event, slick, currentSlide) {
        $(this).parent().find('.items_count').text((currentSlide + 1) + ' / ' + slick.slideCount)
    });
