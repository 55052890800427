import anime from 'animejs/lib/anime.es.js';

function collapse_filter_section(e) {
	let section = e.target.closest('.filter_section');
	let options_list = section.querySelector('.filter_section_options');

	let height = options_list.clientHeight;
	if (options_list.classList.contains('closed')) {
		options_list.style.height = 'auto';
		height = options_list.clientHeight;
		options_list.style.height = 0;
	}
	anime({
		targets: options_list,
		height: (!options_list.classList.contains('closed')) ? [height, 0] : [0, height],
		opacity: (!options_list.classList.contains('closed')) ? [1, 0] : [0, 1],
		easing: 'easeInOutQuad',
		duration: 300,
		complete: function (anim) {
			anim.animatables[0].target.classList.toggle('closed');
		}
	})
	anime({
		targets: e.target.querySelector('svg'),
		rotate: (!options_list.classList.contains('closed')) ? [0, 180] : [180, 0],
		easing: 'easeInOutQuad',
		duration: 300,
	})

}

window.collapse_filter_section = (e) => collapse_filter_section(e);

function collapse_filter_section_price(e) {
	let section = e.target.closest('.filter_section');
	let options_list = section.querySelector('.filter_section_price_slider');

	let height = options_list.clientHeight;
	if (options_list.classList.contains('closed')) {
		options_list.style.height = 'auto';
		height = options_list.clientHeight;
		options_list.style.height = 0;
	}
	anime({
		targets: options_list,
		height: (!options_list.classList.contains('closed')) ? [height, 0] : [0, height],
		opacity: (!options_list.classList.contains('closed')) ? [1, 0] : [0, 1],
		easing: 'easeInOutQuad',
		duration: 300,
		complete: function (anim) {
			anim.animatables[0].target.classList.toggle('closed');
		}
	})
	anime({
		targets: e.target.querySelector('svg'),
		rotate: (!options_list.classList.contains('closed')) ? [0, 180] : [180, 0],
		easing: 'easeInOutQuad',
		duration: 300,
	})

}

window.collapse_filter_section_price = (e) => collapse_filter_section_price(e);

function collapse_menu(e) {
	console.log(e.target);
	let div_el = e.target.closest('div');
	let svg = div_el.querySelector('svg.mobile_menu_arrow');
	let section = e.target.closest('li');
	let options_list = section.querySelector('ul');

	let height = options_list.clientHeight;
	if (options_list.classList.contains('closed')) {
		options_list.style.height = 'auto';
		height = options_list.clientHeight;
		options_list.style.height = 0;
	}
	// if (!options_list.classList.contains('closed')) {
	// 	options_list.style.height = options_list.clientHeight + 'px';
	// 	// height = options_list.clientHeight;
	// 	// options_list.style.height = 0;
	// }
	anime({
		targets: options_list,
		height: (!options_list.classList.contains('closed')) ? [height, 0] : [0, height],
		opacity: (!options_list.classList.contains('closed')) ? [1, 0] : [0, 1],
		easing: 'easeInOutQuad',
		duration: 300,
		complete: function (anim) {
			anim.animatables[0].target.classList.toggle('closed');
			// if (!anim.animatables[0].target.classList.contains('closed')) {
			// 	anim.animatables[0].target.style.height = 'auto';
			// }
		}
	})
	anime({
		targets: svg,
		rotate: (options_list.classList.contains('closed')) ? [0, 90] : [90, 0],
		easing: 'easeInOutQuad',
		duration: 300,
	})

}

window.collapse_menu = (e) => collapse_menu(e);

function collapse_mobile_menu(e) {
	let section = document.querySelector('.sidebar_menu_wrapper');
	let overlay = section.querySelector('.mobile_menu_overlay');

	if (section.classList.contains('closed')) {
		let tl = anime.timeline({
			easing: 'easeInOutQuad',
			duration: 450,
		});
		tl.add({
			targets: section,
			right: (section.classList.contains('closed')) ? ['100%', '0%'] : ['0%', '100%'],
			opacity: (section.classList.contains('closed')) ? [0, 1] : [1, 0],
			easing: 'easeInOutQuad',
			duration: 300,
			complete: function (anim) {
				anim.animatables[0].target.classList.toggle('closed');
			}
		});
		tl.add({
			targets: overlay,
			opacity: (section.classList.contains('closed')) ? [0, 1] : [1, 0],
			easing: 'easeInOutQuad',
			duration: 150,
		});
	} else {
		let tl = anime.timeline({
			easing: 'easeInOutQuad',
			duration: 450,
		});
		tl.add({
			targets: overlay,
			opacity: (section.classList.contains('closed')) ? [0, 1] : [1, 0],
			easing: 'easeInOutQuad',
			duration: 150,
		});
		tl.add({
			targets: section,
			right: (section.classList.contains('closed')) ? ['100%', '0%'] : ['0%', '100%'],
			opacity: (section.classList.contains('closed')) ? [0, 1] : [1, 0],
			easing: 'easeInOutQuad',
			duration: 300,
			complete: function (anim) {
				anim.animatables[0].target.classList.toggle('closed');
			}
		});
	}
}

window.collapse_mobile_menu = (e) => collapse_mobile_menu(e);

