import './bootstrap';
import {Alpine} from './alpine/index';
import mask from '@alpinejs/mask'
import 'livewire-sortable'
import './sliders'
import './animation/home_page'
import './animation/product_page'
import './animation/category_page'
import './accordion'
// import Swiper JS
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';
// import Swiper styles
import 'swiper/css';

import.meta.glob([
	'../images/**',
]);
import * as Sentry from "@sentry/browser";

Sentry.init({
	dsn: "https://761522689b49e36874917d2cfc5642c5@o4507829461712896.ingest.de.sentry.io/4507829480980560",

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [
		Sentry.replayIntegration({
			// Additional SDK configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),
	],
});
Alpine.plugin(mask)

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
function setWindowHeight(){
	var windowHeight = window.innerHeight;
	document.body.style.height = windowHeight + "px";
	// console.log(document.body.style.height);
	let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener("resize",setWindowHeight,false);


Alpine.start();

$(function() {

	var url = window.location.href;

	if (url.search('attr') != -1) {
		$('html, body').animate({
			scrollTop: $('.product_grid_control').offset().top
		}, 500);
	}

});
var video =  document.querySelectorAll('.video_bg video')

//add source to video tag
function addSourceToVideo(element, src) {
	console.log('qwe');
	var source = document.createElement('source');
	source.src = src;
	source.type = 'video/mp4';
	console.log(src);
	element.appendChild(source);

}

//determine screen size and select mobile or desktop vid
function whichSizeVideo(element, src) {
	var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
	console.log(windowWidth);
	if (windowWidth > 767 ) {
		addSourceToVideo( element, src.dataset.desktopVid);
	} else {
		addSourceToVideo(element, src.dataset.mobileVid);
	}
}

//init only if page has videos
function videoSize() {
	if (video !== undefined) {
		video.forEach(function(element, index) {
			whichSizeVideo(
				element, //element
				element  //src locations
			);
		});
	}
}
videoSize();

window.open_filter_sidebar = (e) => open_filter_sidebar(e);
window.hide_filter_sidebar = (e) => hide_filter_sidebar(e);

function open_filter_sidebar(e){
	document.querySelector(".sidebar_filter_overlay").classList.add("show_tablet");
	document.querySelector(".sidebar_filter").classList.add("show_tablet");
	document.querySelector("body").classList.add("block_scroll");
}
function hide_filter_sidebar(e){
	document.querySelector(".sidebar_filter_overlay").classList.remove("show_tablet");
	document.querySelector(".sidebar_filter").classList.remove("show_tablet");
	document.querySelector("body").classList.remove("block_scroll");
}
// .products_grid_wrapper .product_grid_control .selected_filters_container .container_title.show_tablet

window.submit_header_search_form = (e) => submit_header_search_form(e);

function submit_header_search_form(e){
	var form = document.getElementById("header_search_form");
	form.submit();
}



const swiper = new Swiper('.swiper', {
	slidesPerView: 1,
	speed: 400,
	// spaceBetween: 100,
	loop: true,
	centeredSlides: true,
	// autoplay: {
	// 	delay: 2000,
	// },
	modules: [ Navigation, Pagination ],
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + '<svg xmlns="http://www.w3.org/2000/svg" width="9"' +
				' height="8" viewBox="0 0 9 8" fill="none"><circle opacity="0.2" cx="4.5" cy="4" r="4"' +
				' fill="#454B5E"/></svg>'+ '</span>';
		}
	},
	parallax: true,
});

// swiper.on('slideChange', function () {
// 	swiper.render()
// });

// const swiper = document.querySelector('.swiper').swiper;
//
// // Now you can use all slider methods like
// swiper.slideNext();
