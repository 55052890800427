/* 

  HTML:
    <div class="acc_item">
          <div class="acc_title">
              <span>{{$question}}</span><span class="title_icon">@include('front.elements.svg', ['icon' => 'arrow_down'])</span>
          </div>
          <div class="acc_description">
              <p>{{$answer}}}}</p>
          </div>
    </div>

  CSS:
.acc_item {
    border-bottom: 1px solid #E5E7EB;
    padding-bottom: 16px;
    &:last-child {
        border-bottom: none;
    }
    .acc_title {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        column-gap: 24px;
        user-select: none;
        &:hover {
            cursor: pointer;
        }
        .title_icon {
            display: flex;
            align-items: center;
        }
        @include font('Headline 5')
    }
    .acc_description {
        max-height: 0;
        padding-top: 8px;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        color: var(--gray-800, #454B5E);
        max-width: 1192px;

        @include font('Body 3')
    }
}
*/

let acc = document.getElementsByClassName("acc_title");
let i;

for (i = 0; i < acc.length; i++) {
	acc[i].addEventListener("click", function () {
		/* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */

		this.classList.toggle("active");
		// console.log(this.lastElementChild)
		// this.lastElementChild.classList.toggle('rotate')

		// this.lastElementChild.style.transform = 'rotate(-180deg)';

		/* Toggle between hiding and showing the active panel */
		let icon = this.lastElementChild;
		let acc_description = this.nextElementSibling;

		icon.style.transition = "0.2s";

		if (icon.style.transform) {
			icon.style.transform = null;
		} else {
			icon.style.transform = "rotate(-180deg)";
		}

		if (acc_description.style.maxHeight) {
			acc_description.style.maxHeight = null;
		} else {
			acc_description.style.maxHeight = acc_description.scrollHeight + "px";
		}
	});
}
