import anime from 'animejs/lib/anime.es.js';


const home_grid_observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.home_page .home_grid',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const home_grid = document.querySelectorAll('.home_page .home_grid');
home_grid.forEach((el) => home_grid_observer.observe(el))

const slider_new_rec_observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.home_page .slider_new_rec',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const slider_new_rec = document.querySelectorAll('.home_page .slider_new_rec');
slider_new_rec.forEach((el) => slider_new_rec_observer.observe(el))

const slider_new_products_observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.home_page .slider_new_products',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const slider_new_products = document.querySelectorAll('.home_page .slider_new_products');
slider_new_products.forEach((el) => slider_new_products_observer.observe(el))

const comments_slider_observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.home_page .comments_slider',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const comments_slider = document.querySelectorAll('.home_page .comments_slider');
comments_slider.forEach((el) => comments_slider_observer.observe(el))

const anim_servs1_observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.home_page .anim_servs1',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const anim_servs1 = document.querySelectorAll('.home_page .anim_servs1');
anim_servs1.forEach((el) => anim_servs1_observer.observe(el))

const anim_servs2_observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.home_page .anim_servs2',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const anim_servs2 = document.querySelectorAll('.home_page .anim_servs2');
anim_servs2.forEach((el) => anim_servs2_observer.observe(el))

const services_observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.home_page .services',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const services = document.querySelectorAll('.home_page .services');
services.forEach((el) => services_observer.observe(el))
